//
//    Material overrides
//
// Use this stylesheet to add any hardcoded MDC styles and avoid encapsulation overriding in component stylesheets.
// These should be used sparsely, and material component themes should always be considered first.

//.mdc-text-field--filled:not(.mdc-text-field--disabled) {
//    background-color: #ffffff1a;
//}
//
//.mat-mdc-form-field-focus-overlay {
//    background-color: rgba(255, 255, 255, 0.435);
//}

cdk-virtual-scroll-viewport {
    height: 100%;
    width: 100%;

    .cdk-virtual-scroll-content-wrapper {
        max-width: 100% !important;
    }
}
