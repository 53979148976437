@use 'sass:color';
@use 'variables';
@use 'mixins';
@use 'animations';

.eideas-tab__icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    background-image: url(/../../assets/icons/icon-svg.svg);
    background-size: cover;
}

.mat-mdc-tab-header {
    border-bottom: 1px solid variables.$color-dark-gray;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    bottom: 20px;
    background-color: transparent;
}

mat-ink-bar {
    &:before {
        content: '';
        width: 50%;
        height: 2px;
        background-color: #56bc2f;
        position: absolute;
        left: 25%;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-list .mat-tab-labels .mat-tab-label {
    background-color: color.scale(variables.$color-darker-gray, $lightness: -3%);
    font-family: variables.$font-1;
    font-weight: 400;
    margin-bottom: 10px;

    &:hover {
        background-color: color.scale(variables.$color-darker-gray, $lightness: 5%);
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        .mat-tab-label-content {
            color: variables.$color-white;
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-content {
        color: variables.$color-xlight-gray;
        font-weight: variables.$font-weight-semibold;
    }
}

.mat-mdc-tab-group__no-margin {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-labels .mat-tab-label {
        margin-bottom: 0;
    }
}

.mat-mdc-tab-group.mat-tab-group__no-margin .mat-ink-bar {
    bottom: 10px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-list .mat-tab-labels .mat-tab-label-active {
    background-color: color.scale(variables.$color-darker-gray, $lightness: 10%);
    opacity: 1;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-content {
        color: variables.$color-white;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: #4a4a4a;
}
