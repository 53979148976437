.sphere-info-card__data-pair-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 6px;
    align-items: center;
}

.sphere-info-card__expandable-list {
    grid-column: 1 /3;
    max-height: 100px;
    overflow-y: auto;
}

.sphere-info-card__icon {
    svg {
        position: relative;
        top: 3px;
    }
}

.sphere-info-card__data--link {
    color: var(--primary-color);
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
