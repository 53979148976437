/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
.mat-progress-bar-fill::after {
    background-color: #53b32f;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
.mat-progress-bar-buffer {
    background: #2b2b2b;
}

//.mat-mdc-progress-bar {
//    height: 8px !important;
//    border-radius: 15px;
//}
