// Primary color palette
$color-primary: #56bc2f;
$color-primary-hover: #a0ef6e;
$light-primary-text: white;

// Enverus color palette
$color-deep-lake: #03252f;
$color-lake: #2e5665;
$color-lagoon: #006a70;
$color-ocean: #629fa8;
$color-mist: #d0ecee;
$color-sand: #efeee1;
$color-dark-green: #13322b;
$color-blue: #155aa7;
$color-blue-hover: #2b7edc;

// Monochromatic color palette
$color-white: #ffffff;
$color-xxxlight-gray: #f3f3f3;
$color-xxlight-gray: #cdcdcd;
$color-xlight-gray: #bcbcbc;
$color-light-gray: #878787;
$color-medium-gray: #7a7a7a;
$color-base-gray: #5b5b5b;
$color-dark-gray: #454545;
$color-darker-gray: #303030;
$color-xdark-gray: #212121;
$color-xxdark-gray: #171717;
$color-xxxdark-gray: #1b1b1b;
$color-black: #000000;

// Accent color palette
$color-vivid-orange: #ff5f23;
$color-electric-blue: #005af6;
$color-electric-blue-light: #2f79f9;

/* ==========================================================================
   %% Typography Variables %%
   ========================================================================== */

//global font varaibles

$base-line-height: 135%;

// font weights
$font-weight-xlight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-ultra: 800;

//font sizes
$font-size-micro: 0.65rem;
$font-size-tiny: 0.7rem;
$font-size-xxs: 0.8rem;
$font-size-xs: 0.85rem;
$font-size-sm: 0.9rem;
$font-size-base: 1rem;
$font-size-md: 1.1rem;
$font-size-lg: 1.2rem;
$font-size-xl: 1.3rem;
$font-size-xxl: 1.4rem;
$font-size-big: 2rem;
$font-size-jumbo: 2.5rem;

$font-size: $font-size-base;

//Custom font family
$font-1: 'Roboto', Arial, Helvetica, sans-serif;

//Base san-serif font family
$font-2: Arial, 'Helvetica Neue', Helvetica, sans-serif;

//Serif font family
$font-3: TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia, serif;

/* ==========================================================================
   %% Spacing Units %%
   ========================================================================== */

$unit-zero: 0px;
$unit-nano: 1px;
$unit-micro: 2px;
$unit-tiny: 5px;
$unit-xxs: 8px;
$unit-xs: 10px;
$unit-sm: 12px;
$unit-base: 15px;
$unit-md: 20px;
$unit-lg: 30px;
$unit-xl: 40px;
$unit-xxl: 50px;
$unit-jumbo: 75px;

$half-unit-base: $unit-base * 0.5;
$unit: $unit-base;
$base-unit: $unit-base;

/* ==========================================================================
   %% Media Query Variables %%
   ========================================================================== */

// small range (0px --> 640px) ==> Good for mobile devices
$sm-start: 0px;
$sm-end: 640px;

// medium range (640px --> 1150px) ==> Good for tablet devices
$md-start: 640px;
$md-end: 1200px;

// large range (1151px --> 1500px) ==> Good for portable & laptop devices
$lg-start: 1201px;
$lg-end: 1500px;

// xlarge range (1501px --> 1992px)  ==> Good for large screens & desktop
$xl-start: 1501px;
$xl-end: 1992px;

// jumbo range (1993px --> up)  ==> Good for everything above large screens & desktop
$jumbo-start: 1993px;
