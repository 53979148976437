/* You can add global styles to this file, and also use other style files */
@use '@vscode/codicons/dist/codicon.css';
@use 'variables';
@use 'mixins';
@use './theme/entrypoint';
@use './theme/components/ag-grid';
@use './theme/components/highcharts';
@use './theme/element-styling/mat-datepicker';
@use './app/curve-builder/styles/curve-audit-messages';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--font-family-roboto) !important;
}

html,
body {
    background-color: #303030;
    height: 100%;
}

.mat-mdc-card {
    overflow: hidden;
}

.page-content {
    height: calc(100vh - 80px);
}

a {
    text-decoration: none;
    color: inherit;
}

.sphere-anchor {
    color: var(--color-primary) !important;

    &:hover {
        text-decoration: underline;
    }

    &.sphere-anchor--error {
        color: var(--color-warn) !important;
    }

    &.sphere-anchor--accent {
        color: var(--color-accent) !important;
    }

    &.sphere-anchor--plain {
        color: #eaeaeb !important;
    }
}

.sphere-anchor.sphere-anchor--with-icon {
    > mat-icon {
        position: relative;
        top: 7px;
        margin-right: 5px;
    }
}

.spacer {
    flex: 1 1 auto;
}

strong {
    font-weight: 500;
}

.code-box {
    padding: 10px;
    border-radius: 3px;
    background-color: #303030;
    font-family: var(--font-family-monospace);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-content {
    overflow: inherit !important;
}

.ag-font-style {
    user-select: initial !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    -webkit-user-select: text !important;
}

.input-legend {
    position: fixed !important;
    bottom: 0;
    right: 0;
    border: 0 !important;
}

sphere-step-input {
    min-width: 300px !important;
}

.custom-schedule__cron-editor .mat-mdc-form-field {
    width: 150px !important;
}

.inline-icon {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    cursor: pointer !important;
}

.sphere-density__compact {
    .mat-form-field-appearance-fill {
        // show label for more dense inputs as well
        .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined),
        .mat-mdc-floating-label {
            display: inline !important;
        }

        .mdc-floating-label--float-above {
            transform: translateY(-90%) scale(0.75) !important; //was -106% and 0.75
        }

        .mat-mdc-select-value,
        .mdc-text-field__input {
            transform: translateY(5px);
        }
    }

    .mtx-select .ng-select .ng-select-container {
        transform: translateY(5px);

        .ng-clear-wrapper,
        .ng-arrow-wrapper {
            transform: translateY(-5px);
        }
    }

    .mat-mdc-chip-set .mdc-evolution-chip {
        transform: translateY(5px);
    }

    .data-support-date-picker__value {
        position: relative;
        top: 5px;
    }
}
