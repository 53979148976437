.popover-no-backdrop {
}

.sphere-cdk-popover {
    color: var(--color-text);
    background-color: var(--background-color-dark);
    border: 1px solid var(--element-border);
}

.sphere-cdk-popover-backdrop--transparent {
    background-color: rgba(0, 0, 0, 0);
}
