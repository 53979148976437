@use 'variables';

.tile__metadata {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
    margin-top: variables.$unit-xs;
    font-size: variables.$font-size-micro;
    opacity: 0.7;
    align-items: center;
}

.tile__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
}

.tile__actions--left {
    opacity: 0;
    transform: translateX(-80px);
}

.tile__actions--left button {
    font-size: variables.$unit-xs;
    padding: 0 variables.$unit-xs;
    min-width: 0;
}

.text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.market-basis__txt {
    @extend .text-overflow-ellipsis;
    max-width: 70px;
}

.product__txt {
    @extend .text-overflow-ellipsis;
}

.dot {
    width: 3px;
    height: 3px;
    background-color: lightgray;
    border-radius: variables.$unit-tiny;
    opacity: 0.7;
    margin: variables.$unit-nano variables.$unit-tiny variables.$unit-zero variables.$unit-tiny;
}
