.sphere-context-menu {
    display: inline-flex;
    flex-direction: column;
    min-width: 180px;
    max-width: 280px;
    padding: 6px 0;

    box-shadow:
        0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: var(--mdc-theme-surface, #424242);
    color: var(--mdc-theme-on-surface, #fff);
}

.sphere-context-menu-item {
    background-color: transparent;
    cursor: pointer;
    border: none;

    user-select: none;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;

    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;

    font-family: var(--font-family-roboto);
    font-size: 14px;

    &:hover {
        background: rgba(255, 255, 255, 0.08);
    }
    &:active {
        background: rgba(255, 255, 255, 0.1);
    }

    mat-icon {
        color: var(--color-light-gray);
        &:first-child {
            margin-right: 10px;
        }
    }
}

.SphereContextMenu {
    display: inline-flex;
    flex-direction: column;
    min-width: 180px;
    max-width: 280px;
    background-color: var(--card-background);
    padding: 6px 0;
}

.SphereContextMenuItem {
    background-color: transparent;
    cursor: pointer;
    border: none;

    user-select: none;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;

    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;
}

.SphereContextMenuItem:hover {
    background-color: var(--background-color-light);
}

.SphereContextMenuItem:active {
    background-color: var(--background-color-light);
}

.empty-icon {
    display: inline-block;
    flex-shrink: 0;
    margin-right: var(--mat-menu-item-spacing, 12px);
    height: var(--mat-menu-item-icon-size, 24px);
    width: var(--mat-menu-item-icon-size, 24px);
}

.mat-mdc-menu-content mat-divider {
    border-color: var(--element-border);
}
