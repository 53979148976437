/** Generic classes **/

.fill-parent {
    width: 100% !important;
    height: 100% !important;
}

.no-padding {
    padding: 0 !important;
}

.no-border-radius {
    border-radius: 0 !important;
}

.disabled-all {
    opacity: 0.7;
    pointer-events: none;
}

.centered-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-content--h {
    display: flex;
    justify-content: center;
}

.centered-content--v {
    height: 100%;
    display: flex;
    align-items: center;
}

.fab-button-positioning {
    position: fixed !important;
    bottom: 10px;
    right: 10px;
    z-index: 999;
}

.fab-button-wrapper {
    position: fixed !important;
    bottom: 10px;
    right: -28px;
    z-index: 999;

    animation-name: fab-button-emphasis;
    animation-duration: 1.25s;
    animation-iteration-count: 2;

    transform: translateX(0) rotateZ(0);
    transition: transform 0.3s ease-out;
}

.fab-button-wrapper:hover {
    transform: translateX(-38px) rotateZ(360deg);
}

.opacity-0 {
    opacity: 0;
}

@keyframes fab-button-emphasis {
    0% {
        transform: translateX(0) rotateZ(0);
    }
    10% {
        transform: translateX(-10) rotateZ(0);
    }
    30% {
        transform: translateX(-15px) rotateZ(0);
    }
    40% {
        transform: translateX(10px) rotateZ(0);
    }
    60% {
        transform: translateX(0) rotateZ(0);
    }
}

.blue-status-border-left {
    border-left: 3px solid var(--status-blue);
}

.blue-status-border-left--s {
    border-left: 2px solid var(--status-blue);
}

.green-status-border-left {
    border-left: 3px solid var(--primary-color);
}

.green-status-border-left--s {
    border-left: 2px solid var(--primary-color);
}

.purple-status-border-left {
    border-left: 3px solid var(--status-purple);
}

.purple-status-border-left--s {
    border-left: 2px solid var(--status-purple);
}

.orange-status-border-left {
    border-left: 3px solid var(--status-orange);
}

.orange-status-border-left--s {
    border-left: 2px solid var(--status-orange);
}

.red-status-border-left {
    border-left: 3px solid var(--status-red);
}

.red-status-border-left--s {
    border-left: 2px solid var(--status-red);
}

.gray-status-border-left {
    border-left: 3px solid var(--status-gray-dark);
}

.gray-status-border-left--s {
    border-left: 2px solid var(--status-gray-dark);
}
