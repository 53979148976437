.display-flex {
    display: flex;
}

.column-gap--5 {
    grid-column-gap: 5px;
}

.column-gap--10 {
    grid-column-gap: 10px;
}

.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-3 {
    flex: 3;
}
.flex-4 {
    flex: 4;
}
.flex-5 {
    flex: 5;
}
