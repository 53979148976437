/** DEFAULT **/
.curve-audit--message--default--color {
    color: var(--status-darkgray);
}

.curve-audit--message--default--bg-color.cell-status-square,
.curve-audit--message--default--bg-color .cell-status-square {
    background-color: var(--status-darkgray);
    opacity: 0.6;
}

/** UNDEFINED **/
.curve-audit--message--undefined--color {
    color: var(--status-gray);
}

.curve-audit--message--undefined--bg-color.cell-status-square,
.curve-audit--message--undefined--bg-color .cell-status-square {
    background-color: var(--status-gray);
}

/** INFO **/
.curve-audit--message--info--color {
    color: var(--status-blue);
}

.curve-audit--message--info--bg-color.cell-status-square,
.curve-audit--message--info--bg-color .cell-status-square {
    background-color: var(--status-blue);
}

/** WARNING / WARN **/
.curve-audit--message--warn--color,
.curve-audit--message--warning--color {
    color: var(--status-yellow);
}

.curve-audit--message--warn--bg-color.cell-status-square,
.curve-audit--message--warn--bg-color .cell-status-square,
.curve-audit--message--warning--bg-color.cell-status-square,
.curve-audit--message--warning--bg-color .cell-status-square {
    background-color: var(--status-yellow);
}

/** ERROR **/
.curve-audit--message--error--color {
    color: var(--status-red);
}

.curve-audit--message--error--bg-color.cell-status-square,
.curve-audit--message--error--bg-color .cell-status-square {
    background-color: var(--status-red);
}

/** TEST-FAIL **/
.curve-audit--message--test-fail--color {
    color: var(--status-orange);
}

.curve-audit--message--test-fail--bg-color.cell-status-square,
.curve-audit--message--test-fail--bg-color .cell-status-square {
    background-color: var(--status-orange);
}

/** DEBUG **/
.curve-audit--message--debug--color {
    color: var(--status-purple);
}

.curve-audit--message--debug--bg-color.cell-status-square,
.curve-audit--message--debug--bg-color .cell-status-square {
    background-color: var(--status-purple);
}
