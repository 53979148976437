.text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}

.text-overflow-ellipsis--wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
}

.text-monospace {
    font-family: var(--font-family-monospace);
}

.text-primary {
    color: var(--primary-color);
}

.text-primary-dark {
    color: var(--primary-color-dark);
}

.text-subtle {
    color: var(--color-text-subtle);
}

.text-subtle-dark {
    color: var(--color-text-subtle-dark);
}

.text-em {
    font-style: italic;
}

.text-weight-400 {
    font-weight: 400;
}

.text-weight-500 {
    font-weight: 500;
}

.text-weight-600 {
    font-weight: 600;
}
