@use 'highcharts/css/stocktools/gui.css';
@use 'highcharts/css/annotations/popup.css';
@use 'highcharts-mixins' as hcmixins;

/** Styles **/

:root {
    --highcharts-background-color: #111111;
    --highcharts-neutral-color-3: #222222;
    --highcharts-neutral-color-10: #292929;
    --highcharts-neutral-color-60: #ffffff;
}

.highcharts-title,
.highcharts-subtitle {
    text-transform: uppercase;
}

.highcharts-tooltip text {
    fill: #f0f0f0;
}

.highcharts-range-selector-buttons text {
    fill: silver;
}

.highcharts-yaxis-grid {
    stroke-width: 1px;
}

.highcharts-axis-labels,
.highcharts-axis-title {
    fill: #e0e0e3;
}

.highcharts-navigator .highcharts-navigator-handle {
    fill: #666;
    stroke: #aaa;
}

.highcharts-navigator .highcharts-navigator-outline {
    stroke: #ccc;
}

.highcharts-navigator .highcharts-navigator-xaxis .highcharts-grid-line {
    stroke: #2f2f2f;
}

.highcharts-scrollbar .highcharts-scrollbar-rifles {
    stroke: #fff;
}

.highcharts-scrollbar .highcharts-scrollbar-button {
    stroke: #606063;
    fill: #606063;
}

.highcharts-scrollbar .highcharts-scrollbar-arrow {
    fill: #ccc;
}

.highcharts-scrollbar .highcharts-scrollbar-thumb {
    fill: #808083;
    stroke: #808083;
}

.highcharts-contextbutton .highcharts-button-symbol {
    stroke: #dddddd;
}

/**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
.highcharts-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0;
    /* #1072 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: var(--font-family);
    font-size: 12px;
}

.highcharts-root {
    display: block;
}

.highcharts-root text {
    stroke-width: 0;
}

.highcharts-strong {
    font-weight: bold;
}

.highcharts-emphasized {
    font-style: italic;
}

.highcharts-anchor {
    cursor: pointer;
}

.highcharts-background {
    fill: rgba(0, 0, 0, 0);
}

.highcharts-plot-border,
.highcharts-plot-background {
    fill: none;
}

.highcharts-label-box {
    fill: none;
}

.highcharts-button-box {
    fill: inherit;
}

.highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    fill: none;
}

.highcharts-tracker-area {
    fill: rgba(192, 192, 192, 0.0001);
    stroke-width: 0;
}

/* Titles */
.highcharts-title {
    fill: #e0e0e3;
    font-size: 20px;
}

.highcharts-subtitle {
    fill: #e0e0e3;
}

/* Axes */
.highcharts-axis-line {
    fill: none;
    stroke: #707073;
}

.highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-axis-title {
    fill: #e0e0e3;
}

.highcharts-axis-labels {
    fill: #e0e0e3;
    cursor: default;
    font-size: 0.9em;
    font-family: var(--font-family-monospace);
}

.highcharts-grid-line {
    fill: none;
    stroke: #2f2f2f;
}

.highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 0px;
}

.highcharts-tick {
    stroke: #707073;
}

.highcharts-yaxis .highcharts-tick {
    stroke-width: 0;
}

.highcharts-minor-grid-line {
    stroke: #505053;
}

.highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: #606063;
}

.highcharts-crosshair-category {
    stroke: #707073;
    stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
    cursor: pointer;
    fill: #666;
    font-size: 0.7em;
    transition:
        fill 250ms,
        font-size 250ms;
}

.highcharts-credits:hover {
    fill: black;
    font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms;
}

.highcharts-tooltip text {
    fill: #e0e0e3;
}

.highcharts-tooltip .highcharts-header {
    font-size: 0.85em;
}

.highcharts-tooltip-box {
    stroke-width: 1px;
    fill: rgba(0, 0, 0, 0.85);
    fill-opacity: 0.85;
}

.highcharts-tooltip-box .highcharts-label-box {
    fill: rgba(0, 0, 0, 0.85);
    fill-opacity: 0.85;
}

.highcharts-selection-marker {
    fill: #335cad;
    fill-opacity: 0.25;
}

.highcharts-graph {
    fill: none;
    //stroke-width: 1px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
    stroke-width: 3;
}

.highcharts-state-hover path {
    transition: stroke-width 50;
    /* quick in */
}

.highcharts-state-normal path {
    transition: stroke-width 250ms;
    /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
    transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
    opacity: 0.2;
}

/** Alternative series color versions (DHWS) **/
.highcharts--custom-series-dhws {
    /* Series options */
    /* Default colors */
    .highcharts-color-0 {
        @include hcmixins.gryphon-series(var(--status-alt-green));
    }

    .highcharts-color-1 {
        @include hcmixins.gryphon-series(var(--status-alt-yellow));
    }

    .highcharts-color-2 {
        @include hcmixins.gryphon-series(var(--status-alt-red));
    }

    .highcharts-color-3 {
        @include hcmixins.gryphon-series(var(--status-alt-blue));
    }
}

/* Series options */
/* Default colors */
.highcharts-color-0 {
    @include hcmixins.gryphon-series(var(--status-blue-light));
}

.highcharts-color-1 {
    @include hcmixins.gryphon-series(var(--status-green-light));
}

.highcharts-color-2 {
    @include hcmixins.gryphon-series(var(--status-red-light));
}

.highcharts-color-3 {
    @include hcmixins.gryphon-series(var(--status-blue));
}

.highcharts-color-4 {
    @include hcmixins.gryphon-series(var(--status-yellow));
}

.highcharts-color-5 {
    @include hcmixins.gryphon-series(var(--status-red));
}

.highcharts-color-6 {
    @include hcmixins.gryphon-series(var(--status-purple));
}

.highcharts-color-7 {
    @include hcmixins.gryphon-series(var(--status-green));
}

.highcharts-color-8 {
    @include hcmixins.gryphon-series(var(--status-orange));
}

.highcharts-color-9 {
    @include hcmixins.gryphon-series(var(--status-green-dark));
}

.highcharts-color-10 {
    @include hcmixins.gryphon-series(var(--status-gray));
}

.highcharts-color-11 {
    @include hcmixins.gryphon-series(#505050);
}

.highcharts-color-101 {
    fill: #56bc2f !important;
    stroke: #56bc2f !important;

    .highcharts-point {
        fill: #56bc2f !important;
        stroke: #56bc2f !important;
    }
}
.highcharts-color-102 {
    fill: yellowgreen !important;
    stroke: yellowgreen !important;

    .highcharts-point {
        fill: yellowgreen !important;
        stroke: yellowgreen !important;
    }
}
.highcharts-color-103 {
    fill: var(--status-yellow) !important;
    stroke: var(--status-yellow) !important;

    .highcharts-point {
        fill: var(--status-yellow) !important;
        stroke: var(--status-yellow) !important;
    }
}
.highcharts-color-104 {
    fill: var(--status-orange-light) !important;
    stroke: var(--status-orange-light) !important;

    .highcharts-point {
        fill: var(--status-orange-light) !important;
        stroke: var(--status-orange-light) !important;
    }
}
.highcharts-color-105 {
    fill: var(--color-vivid-orange) !important;
    stroke: var(--color-vivid-orange) !important;

    .highcharts-point {
        fill: var(--color-vivid-orange) !important;
        stroke: var(--color-vivid-orange) !important;
    }
}
.highcharts-color-106 {
    fill: var(--color-warn) !important;
    stroke: var(--color-warn) !important;

    .highcharts-point {
        fill: var(--color-warn) !important;
        stroke: var(--color-warn) !important;
    }
}

@include hcmixins.highcharts-series-color-scale(green, #2a5e16);
@include hcmixins.highcharts-series-color-scale(gray, #363636);
@include hcmixins.highcharts-series-color-scale(blue, #151e5d);

.highcharts-area {
    fill-opacity: 0.2;
    stroke-width: 1;
}

.highcharts-markers {
    stroke-width: 1px;
    stroke: #2a2a2b;
}

.highcharts-point {
    stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
    stroke-width: 0;
}

.highcharts-data-label {
    font-size: 0.9em;
    font-weight: bold;
}

.highcharts-data-label-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-data-label text,
text.highcharts-data-label {
    fill: #b0b0b3;
}

.highcharts-data-label-connector {
    fill: none;
}

.highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
    fill: #606063;
    stroke: #fff;
}

.highcharts-column-series rect.highcharts-point {
    stroke: #2a2a2b;
    stroke-width: 0;
}

.highcharts-column-series .highcharts-point {
    transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #2a2a2b;
    stroke-width: 0;
}

.highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #2a2a2b;
}

.highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
    stroke-linejoin: round;
    stroke: #2a2a2b;
}

.highcharts-pyramid-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
    fill: inherit;
    stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
    stroke-width: 1px;
    stroke: #707073;
    transition:
        stroke 250ms,
        fill 250ms,
        fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
    stroke: #666;
    transition:
        stroke 25ms,
        fill 25ms,
        fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
    display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
    fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
    fill-opacity: 0.15;
    cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
    fill-opacity: 0.75;
}

/* Legend */
.highcharts-legend-box {
    fill: none;
    stroke-width: 0;
}

.highcharts-legend-item > text {
    fill: #e0e0e3;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
    stroke-width: 0;
}

.highcharts-legend-item:hover text {
    fill: #fff;
}

.highcharts-legend-item-hidden * {
    fill: #606063 !important;
    stroke: #606063 !important;
    transition: fill 250ms;
}

.highcharts-legend-nav-active {
    fill: #f0f0f3;
    cursor: pointer;
}

.highcharts-legend-nav-inactive {
    fill: #606063;
}

.highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
}

/* Bubble legend */
.highcharts-bubble-legend-symbol {
    stroke-width: 2;
    fill-opacity: 0.5;
}

.highcharts-bubble-legend-connectors {
    stroke-width: 1;
}

.highcharts-bubble-legend-labels {
    fill: #e0e0e3;
}

/* Loading */
.highcharts-loading {
    position: absolute;
    background-color: #2a2a2b;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms;
}

.highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition:
        opacity 250ms,
        height 250ms step-end;
}

.highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band,
.highcharts-pane {
    fill: #fff;
    fill-opacity: 0.05;
}

.highcharts-plot-line {
    fill: none;
    stroke: #666;
    stroke-width: 1px;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
    fill: #2a2a2b;
}

.highcharts-boxplot-median {
    stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
    stroke: #fff;
}

.highcharts-gauge-series .highcharts-data-label-box {
    stroke: #606063;
    stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
    fill: #fff;
    stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
    stroke: #e0e0e3;
    stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
    transition:
        fill 250ms,
        fill-opacity 250ms;
    fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
    transition:
        fill 50ms,
        fill-opacity 50ms;
    fill-opacity: 1;
}

.highcharts-venn-series .highcharts-point {
    fill-opacity: 0.75;
    stroke: #606063;
    transition:
        stroke 250ms,
        fill-opacity 250ms;
}

.highcharts-venn-series .highcharts-point-hover {
    fill-opacity: 1;
    stroke: #606063;
}

/* Highstock */
.highcharts-navigator-mask-outside {
    fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
    fill: rgba(255, 255, 255, 0.1);
    /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize;
}

.highcharts-navigator-outline {
    stroke: #606063;
    fill: none;
}

.highcharts-navigator-handle {
    stroke: #606063;
    fill: #505053;
    cursor: ew-resize;
}

.highcharts-navigator-series {
    @extend .gryphon-series-green;
}

.highcharts-navigator-series .highcharts-point {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
}

.highcharts-navigator-series .highcharts-graph {
    stroke-width: 0px;
}

.highcharts-navigator-series .highcharts-area {
    fill-opacity: 0.25;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: #707073;
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: #666;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0;
}

.highcharts-scrollbar-thumb {
    fill: #606063;
    stroke: #606063;
    stroke-width: 1px;
}

.highcharts-scrollbar-button {
    fill: #707073;
    stroke: #606063;
    stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
    fill: #e0e0e3;
}

.highcharts-scrollbar-rifles {
    stroke: #e0e0e3;
    stroke-width: 1px;
}

.highcharts-scrollbar-track {
    fill: #404043;
    stroke: #404043;
    stroke-width: 1px;
}

.highcharts-button {
    fill: #505053;
    stroke: #606063;
    cursor: default;
    stroke-width: 1px;
    transition: fill 250ms;
}

.highcharts-button text {
    fill: #ccc;
}

.highcharts-button-hover {
    transition: fill 0ms;
    fill: #707073;
    stroke: #606063;
}

.highcharts-button-hover text {
    fill: #fff;
}

.highcharts-button-pressed {
    font-weight: bold;
    fill: #000003;
    stroke: #606063;
}

.highcharts-button-pressed text {
    fill: #fff;
    font-weight: bold;
}

.highcharts-button-disabled text {
    fill: #ccc;
}

.highcharts-range-selector-buttons .highcharts-button {
    stroke-width: 0px;
}

.highcharts-range-label rect {
    fill: none;
}

.highcharts-range-label text {
    fill: #e0e0e3;
}

.highcharts-range-input rect {
    fill: none;
}

.highcharts-range-input text {
    fill: silver;
}

.highcharts-range-input {
    stroke-width: 1px;
    stroke: #505053;
}

input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px;
    /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em;
    /* #4798 */
}

.highcharts-crosshair-label text {
    fill: #2a2a2b;
    font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
    fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
    stroke: #fff;
    stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
    fill: #2a2a2b;
}

.highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
    stroke: #666;
    fill: #2a2a2b;
    transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
    stroke: #fff;
    fill: #707073;
}

.highcharts-flags-series .highcharts-point text {
    fill: #fff;
    font-size: 0.9em;
    font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
    transition:
        fill 500ms,
        fill-opacity 500ms,
        stroke-width 250ms;
    stroke: #606063;
}

.highcharts-map-series .highcharts-point-hover {
    transition:
        fill 0ms,
        fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
    fill: none;
}

.highcharts-heatmap-series .highcharts-point {
    stroke-width: 0;
}

.highcharts-map-navigation {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
}

.highcharts-coloraxis {
    stroke-width: 0;
}

.highcharts-coloraxis-marker {
    fill: #666;
}

.highcharts-null-point {
    fill: #f7f7f7;
}

/* 3d charts */
.highcharts-3d-frame {
    fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
    fill: #505053;
    /* needed to capture hover */
    stroke: none;
    stroke-linecap: round;
}

.highcharts-contextbutton:hover {
    fill: #707073;
    stroke: #707073;
}

.highcharts-button-symbol {
    stroke: #e0e0e3;
    stroke-width: 3px;
}

.highcharts-menu {
    border: 1px solid #666;
    background: #2a2a2b;
    padding: 5px 0;
    box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: #e0e0e3;
    cursor: pointer;
    transition:
        background 250ms,
        color 250ms;
}

.highcharts-menu-item:hover {
    background: #335cad;
    color: #2a2a2b;
}

/* Drilldown module */
.highcharts-drilldown-point {
    cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: #f0f0f3;
    font-weight: bold;
    text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
    font-weight: bold;
    font-size: 12px;
    fill: #e0e0e3;
}

/* Drag-panes module */
.highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: black;
    stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
    stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
    stroke-width: 1px;
    stroke: #e0e0e3;
}

.highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: #e0e0e3;
}

/* Annotations module */
.highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: #fff;
    fill: #fff;
    fill-opacity: 0.75;
}

.highcharts-annotation-label text {
    fill: #707073;
}

/* Gantt */
.highcharts-treegrid-node-collapsed,
.highcharts-treegrid-node-expanded {
    cursor: pointer;
}

.highcharts-point-connecting-path {
    fill: none;
}

.highcharts-grid-axis .highcharts-tick {
    stroke-width: 1px;
}

.highcharts-grid-axis .highcharts-axis-line {
    stroke-width: 1px;
}

/** Custom Gryphon styles **/

.highcharts-plot-band-label {
    fill: rgb(219, 219, 219);
    font-size: 0.6rem;
}

.sphere-plot-area {
    stroke-width: 1px;
}

/** Custom series colours **/

@include hcmixins.gryphon-series-wrapper('green', var(--status-green), var(--status-green-light), var(--status-green-dark));
@include hcmixins.gryphon-series-wrapper('red', var(--status-red), var(--status-red-light), var(--status-red-dark));
@include hcmixins.gryphon-series-wrapper('yellow', var(--status-yellow), var(--status-yellow-light), var(--status-yellow-dark));
@include hcmixins.gryphon-series-wrapper('orange', var(--status-orange), var(--status-orange-light), var(--status-orange-dark));
@include hcmixins.gryphon-series-wrapper('gray', var(--status-gray), var(--status-gray-light), var(--status-gray-dark));
@include hcmixins.gryphon-series-wrapper('darkgray', var(--status-darkgray), var(--status-darkgray-light), var(--status-darkgray-dark));
@include hcmixins.gryphon-series-wrapper('blue', var(--status-blue), var(--status-blue-light), var(--status-blue-dark));
@include hcmixins.gryphon-series-wrapper(
    'electric-blue',
    var(--color-electric-blue),
    var(--color-electric-blue-light),
    var(--color-electric-blue-dark)
);
@include hcmixins.gryphon-series-wrapper('purple', var(--status-purple), var(--status-purple-light), var(--status-purple-dark));

/** Alternative palette (DHWS) **/

@include hcmixins.gryphon-series-simple-wrapper(alt-green, var(--status-alt-green));
@include hcmixins.gryphon-series-simple-wrapper(alt-red, var(--status-alt-red));
@include hcmixins.gryphon-series-simple-wrapper(alt-yellow, var(--status-alt-yellow));
@include hcmixins.gryphon-series-simple-wrapper(alt-blue, var(--status-alt-blue));

/** Custom data point marker classes **/

.sphere-highcharts-point-green {
    fill: var(--status-green) !important;
    stroke: var(--status-green) !important;
}
.sphere-highcharts-point-red {
    fill: var(--status-red) !important;
    stroke: var(--status-red) !important;
}
.sphere-highcharts-point-orange {
    fill: var(--status-orange) !important;
    stroke: var(--status-orange) !important;
}
.sphere-highcharts-point-yellow {
    fill: var(--status-yellow) !important;
    stroke: var(--status-yellow) !important;
}
.sphere-highcharts-point-blue {
    fill: var(--status-blue) !important;
    stroke: var(--status-blue) !important;
}
.sphere-highcharts-point-purple {
    fill: var(--status-purple) !important;
    stroke: var(--status-purple) !important;
}

/** Tooltip helpers **/
.sphere-highcharts-tooltip-wrapper {
    font-family: var(--font-family-roboto);
    p {
        font-weight: 400;
        color: #cccccc;
        font-size: 11px;
    }
    p.sphere-highcharts-tooltip-subheader {
        font-size: 12px;
        color: #fcfcfc;
    }
}
.sphere-highcharts-tooltip-section {
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}
.sphere-highcharts-tooltip-header {
    margin-bottom: 5px;
}
.sphere-highcharts-tooltip-header-square {
    height: 5px;
    width: 5px;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 2px;
}
.sphere-highcharts-tooltip-separator {
    width: 100%;
    height: 1px;
    background-color: #464646;
    margin: 10px 0;
}
.sphere-highcharts-tooltip-value {
    font-weight: 600;
}
.sphere-highcharts-tooltip-list {
    list-style: square;
    color: #a5a5a5;
    padding-left: 15px;
    font-size: 11px;
}
.sphere-highcharts-tooltip-text-valid {
    color: var(--status-green);
    fill: var(--status-green);
}
.sphere-highcharts-tooltip-text-failed {
    color: var(--status-red);
    fill: var(--status-red);
}
.sphere-highcharts-tooltip-text-plausible {
    color: var(--status-yellow);
    fill: var(--status-yellow);
}
.sphere-highcharts-tooltip-text-unchecked {
    color: var(--status-gray-light);
    fill: var(--status-gray-light);
}

/* Custom styles */
.highcharts--custom-color-warn {
    // Flags
    .highcharts-flags-series .highcharts-point .highcharts-label-box {
        stroke: var(--color-warn) !important;
        fill: #2a2a2b;
        transition: fill 250ms;
    }

    .highcharts-flags-series .highcharts-point .highcharts-label-box {
        stroke: #666;
        fill: #2a2a2b;
        transition: fill 250ms;
    }

    .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
        stroke: #fff;
        fill: #666;
    }

    .highcharts-flags-series .highcharts-point text {
        fill: var(--color-warn);
        font-size: 0.9em;
        font-weight: bold;
    }
    // Plot bands
    .highcharts-plot-band,
    .highcharts-pane {
        fill: var(--color-warn);
        fill-opacity: 0.05;
    }
    .highcharts-plot-line {
        fill: none;
        stroke: var(--color-warn);
        stroke-width: 1px;
    }
    // Points
    .highcharts-point {
        fill: var(--color-warn);
        stroke: var(--color-warn);
    }
}

// Stock Tools
.highcharts-annotation-shapes {
    path:first-child {
        stroke: var(--primary-color);
    }
}

.highcharts-annotation-toolbar {
    // We are overriding annotation shapes color, so not allowing to edit them
    .highcharts-annotation-edit-button {
        display: none;
    }
}
.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li,
.highcharts-toggle-toolbar {
    background-color: #111111 !important;
}
.highcharts-bindings-wrapper .highcharts-submenu-wrapper {
    background-color: transparent !important;
}

.highcharts-bindings-wrapper li.highcharts-active > button.highcharts-menu-item-btn,
.highcharts-bindings-wrapper li > button.highcharts-menu-item-btn:hover,
.highcharts-bindings-wrapper .highcharts-arrow-wrapper > div:hover,
.highcharts-bindings-wrapper li.highcharts-active,
.highcharts-toggle-toolbar:hover {
    background-color: var(--highcharts-neutral-color-100) !important;
    transition: background-color 100ms;
}

.highcharts-annotation-label text {
    fill: #fff !important;
}
.highcharts-annotation-label-box {
    stroke: #bdbdbd;
    fill: #000000;
}

defs marker[id^='highcharts-'][id$='-arrow'] path {
    stroke: var(--primary-color) !important;
    fill: var(--primary-color) !important;
}

circle,
ellipse,
.highcharts-measure-crosshair-x,
.highcharts-measure-crosshair-y,
.highcharts-annotation-label-box {
    stroke: var(--primary-color) !important;
}
