@use './mixins.scss';

@keyframes spingear {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes backarrow {
    0% {
        left: 0;
    }

    50% {
        left: -3px;
    }

    100% {
        left: 0;
    }
}

@keyframes rotating-loop {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(1080deg);
    }
}

.rotating-loop {
    animation:
        0.25s ease-in 0s 1 normal none running fadein,
        2.5s ease-in-out 0s infinite normal none running rotating-loop;
}

@keyframes fading-loop {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.fading-loop {
    animation: 1.5s ease-in 0s infinite normal none running fading-loop;
}

@include mixins.pulse-animation(var(--color-primary-rgb), primary);
@include mixins.pulse-animation(var(--color-accent-rgb), accent);
@include mixins.pulse-animation(var(--color-warn-rgb), warn);
@include mixins.pulse-animation(var(--status-green-rgb), green);
@include mixins.pulse-animation(var(--status-red-rgb), red);
@include mixins.pulse-animation(var(--status-yellow-rgb), yellow);
@include mixins.pulse-animation(var(--status-orange-rgb), orange);
@include mixins.pulse-animation(var(--status-gray-rgb), gray);
@include mixins.pulse-animation(var(--status-darkgray-rgb), darkgray);
@include mixins.pulse-animation(var(--status-blue-rgb), blue);
@include mixins.pulse-animation(var(--status-darkblue-rgb), darkblue);
@include mixins.pulse-animation(var(--status-purple-rgb), purple);
@include mixins.pulse-animation(var(--status-black-rgb), black);

@include mixins.pulse-animation-no-scale(var(--color-primary-rgb), primary);
@include mixins.pulse-animation-no-scale(var(--color-accent-rgb), accent);
@include mixins.pulse-animation-no-scale(var(--color-warn-rgb), warn);
@include mixins.pulse-animation-no-scale(var(--status-green-rgb), green);
@include mixins.pulse-animation-no-scale(var(--status-red-rgb), red);
@include mixins.pulse-animation-no-scale(var(--status-yellow-rgb), yellow);
@include mixins.pulse-animation-no-scale(var(--status-orange-rgb), orange);
@include mixins.pulse-animation-no-scale(var(--status-gray-rgb), gray);
@include mixins.pulse-animation-no-scale(var(--status-darkgray-rgb), darkgray);
@include mixins.pulse-animation-no-scale(var(--status-blue-rgb), blue);
@include mixins.pulse-animation-no-scale(var(--status-darkblue-rgb), darkblue);
@include mixins.pulse-animation-no-scale(var(--status-purple-rgb), purple);
@include mixins.pulse-animation-no-scale(var(--status-black-rgb), black);
