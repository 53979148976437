// Legacy button size classes

.button-small {
    &.mat-mdc-icon-button,
    .mat-icon-button {
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 2px;
    }

    &.mat-mdc-button,
    .mat-button,
    &.mat-mdc-unelevated-button,
    &.mat-mdc-outlined-button,
    .mat-flat-button,
    .mat-stroked-button {
        height: 25px;
        line-height: 20px;
        border-radius: 2px;
    }
}

.button-xs {
    &.mat-mdc-icon-button,
    .mat-icon-button {
        width: 18px;
        height: 18px;
        line-height: 18px;
    }

    &.mat-mdc-button,
    .mat-button,
    &.mat-mdc-unelevated-button,
    &.mat-mdc-outlined-button,
    .mat-flat-button,
    .mat-stroked-button {
        height: 18px;
        line-height: 18px;
    }
}
