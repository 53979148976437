@use 'variables';
.no-padding-dialog {
    .mat-mdc-dialog-container {
        margin: 0;
        padding: 0;
    }

    .mat-mdc-dialog-content {
        margin: 0;
        padding: 0;
    }
}

.mat-mdc-dialog-container {
    //background-color: variables.$color-xdark-gray;

    .mat-mdc-dialog-actions {
        padding: 15px;
    }

    .mat-mdc-dialog-content {
        .mat-mdc-card.outline {
            background-color: #090909;
            border: 0;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
        .mat-card-header-text {
            margin-left: variables.$unit-tiny;

            .mat-mdc-card-title {
                margin-bottom: 0;
                font-family: variables.$font-1;
                font-size: variables.$font-size-base;
            }

            .mat-mdc-card-subtitle {
                margin-top: 0;
                margin-bottom: 0;
                font-family: variables.$font-1;
                font-size: variables.$font-size-xs;
                color: variables.$color-light-gray;
            }
        }

        .mat-mdc-dialog-title {
            color: variables.$color-white;
            font-family: variables.$font-1;
            font-size: variables.$font-size-xl;
            font-weight: variables.$font-weight-bold;
        }
    }
}

.mat-mdc-dialog .mat-mdc-dialog-content {
    color: var(--color-text-subtle);
}

.black-background-dialog {
    .mat-mdc-dialog-container {
        background-color: var(--background-color-dark);

        .mat-mdc-card {
            background-color: var(--background-color-dark);
        }

        .mat-action-list .mat-mdc-list-item {
            background-color: variables.$color-black;
        }

        .mat-action-list .mat-mdc-list-item:hover,
        .mat-action-list .mat-list-item:focus {
            background: rgba(255, 255, 255, 0.08);
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
.mat-dialog--primary,
.mat-dialog--accent,
.mat-dialog--warn {
    .mat-mdc-dialog-container {
        border-top-width: 3px;
        border-top-style: solid;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
.mat-dialog--primary .mat-mdc-dialog-container {
    border-top-color: var(--color-primary);
}
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
.mat-dialog--accent .mat-mdc-dialog-container {
    border-top-color: var(--color-accent);
}
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
.mat-dialog--warn .mat-mdc-dialog-container {
    border-top-color: var(--color-warn);
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
.mat-dialog--fullscreen {
    max-width: 100vw !important;

    &.mat-dialog--no-overflow > mat-dialog-container .mat-mdc-dialog-surface {
        border-radius: 0 !important;
        overflow: hidden !important;
    }
}

.mat-dialog-z-1 {
    z-index: 1;
}
