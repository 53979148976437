@use 'variables';

/*
MEDIA QUERY MIXIN

--- USAGE ---

  @include mq(sm);
  @include mq(md);
  @include mq(lg);
  @include mq(xl);
  @include mq(jumbo);

--------------
% */

@mixin mq($query) {
    @if $query == sm {
        @media only screen and (max-width: $sm-end) {
            @content;
        }
    }

    @if $query == md {
        @media only screen and (min-width: $md-start) and (max-width: $md-end) {
            @content;
        }
    }

    @if $query == lg {
        @media only screen and (min-width: $lg-start) and (max-width: $lg-end) {
            @content;
        }
    }

    @if $query == xl {
        @media only screen and (min-width: $xl-start) and (max-width: $xl-end) {
            @content;
        }
    }

    @if $query == jumbo {
        @media only screen and (min-width: $jumbo-start) {
            @content;
        }
    }
}

@mixin pulse-animation($rgb-color-variable, $name) {
    .#{'' + $name}-pulse-base {
        background-color: rgba($rgb-color-variable, 1);
    }

    .#{'' + $name}-pulse {
        box-shadow: 0 0 0 0 rgba($rgb-color-variable, 1);
        animation: #{'' + $name}-pulse 2s infinite;
    }

    @keyframes #{"" + $name}-pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba($rgb-color-variable, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba($rgb-color-variable, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba($rgb-color-variable, 0);
        }
    }
}

@mixin pulse-animation-no-scale($rgb-color-variable, $name) {
    .#{'' + $name}-pulse-base {
        background-color: rgba($rgb-color-variable, 1);
    }

    .#{'' + $name}-pulse-no-scale {
        box-shadow: 0 0 0 0 rgba($rgb-color-variable, 1);
        animation: #{'' + $name}-pulse-no-scale 2s infinite;
    }

    @keyframes #{"" + $name}-pulse-no-scale {
        0% {
            box-shadow: 0 0 0 0 rgba($rgb-color-variable, 0.7);
        }

        70% {
            box-shadow: 0 0 0 10px rgba($rgb-color-variable, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba($rgb-color-variable, 0);
        }
    }
}
