@use 'variables';
.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.7);
}

.sphere-wizard--dialog-content.mat-mdc-dialog-content {
    padding: 0;
    margin: 0;
}

.sphere-wizard--dialog-actions.mat-mdc-dialog-actions {
    background-color: variables.$color-xdark-gray;
    margin-left: -24px;
    margin-right: -24px;
    padding: 8px;
}

.large-wizard-button {
    height: 45px;
}

.sphere-wizard--dialog-actions--wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 10px 5px 10px;
}

.sphere-wizard--dialog {
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
        min-width: 0 !important;
    }

    .mat-stepper-horizontal {
        background-color: variables.$color-black;
    }

    .mat-horizontal-stepper-header-container {
        background-color: variables.$color-xdark-gray;
        border-bottom: 1px solid var(--primary-color);
        padding: 8px;
        margin-bottom: 15px;
    }

    .mat-horizontal-content-container {
        padding: 24px 48px 24px 48px !important;
    }

    .mat-step-header .mat-step-icon {
        background-color: var(--element-border);
    }

    // TODO: Consider using material style CDK to change global mat-stepper instances.
    .mat-step-header .mat-step-icon-selected {
        background-color: #fafafa !important;
        color: #3c3c3c !important;
        font-weight: 500;
    }

    .mat-step-header .mat-step-icon-state-edit {
        background-color: var(--primary-color);
    }
}
