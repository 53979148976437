/**
      GLOBAL SCROLLBAR STYLES
*/

:root {
    color-scheme: dark;
}

* {
    scrollbar-width: thin !important;
}

//::-webkit-scrollbar {
//width: 5px;
//height: 5px;
//}

//::-webkit-scrollbar-button {
//}
//
//::-webkit-scrollbar-track {
//}
//
//::-webkit-scrollbar-track-piece {
//}
//
//::-webkit-scrollbar-thumb {
//    background-color: rgba(0, 0, 0, 0.3);
//}
//
//::-webkit-scrollbar-corner {
//    background-color: rgba(0, 0, 0, 0);
//}
//
//::-webkit-resizer {
//}
