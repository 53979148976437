// Material Datepicker custom date header label
mat-custom-header .mat-custom-controls {
    padding: 10px 5px 10px 5px !important;
}
mat-custom-header .mat-custom-controls .mat-custom-date-monthday {
    font-size: 22px !important;
}

mat-custom-header .mat-custom-controls .mat-custom-date-monthday .mdc-button__label {
    display: flex;
    height: 100%;
    white-space: nowrap;
}

mat-custom-header .mat-custom-controls .mat-custom-time-minute,
mat-custom-header .mat-custom-controls .mat-custom-time-hour {
    display: flex;
    flex-direction: row;
    place-content: flex-end flex-end;
    align-items: center;
    font-size: 22px !important;
    line-height: 36px;
}

.mat-datepicker-toggle {
    button {
        background-color: rgba(0, 0, 0, 0);
        cursor: pointer;
        border: none;
    }
}

mat-custom-header .mat-custom-prev-next {
    .mat-calendar-previous-button,
    .mat-calendar-next-button {
        background-color: rgba(0, 0, 0, 0);
        width: 40px;
        height: 40px;
        cursor: pointer;
        border: none;
    }

    .mat-calendar-previous-button:after,
    .mat-calendar-next-button:after {
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
    }

    .mat-calendar-previous-button:after {
        transform: rotate(135deg);
    }

    .mat-calendar-next-button:after {
        transform: rotate(-45deg);
    }

    .mat-calendar-previous-button:disabled,
    .mat-calendar-next-button:disabled {
        color: #ffffff4d;
        cursor: default;
    }
}

.mat-datepicker__MatheoFixStyles .mat-calendar-body-cell {
    position: relative !important;
}
