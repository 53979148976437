/** Log message status **/

.status--primary {
    color: var(--primary-color);
}

.status--success {
    color: var(--status-green);
}

.status--error {
    color: var(--status-red);
}

.status--warning {
    color: var(--status-yellow);
}

.status--info {
    color: var(--status-blue);
}

.status--debug {
    color: var(--status-purple);
}
