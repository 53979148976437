.sphere-chip-wrapper {
    background-color: #3a3a3a;
    border: 1px solid var(--element-border);
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    &:last-of-type {
        margin-right: 0;
    }
    &:hover {
        background-color: #4e4e4e;
    }
    &:active {
        outline: none;
    }
}
.sphere-chip {
    padding: 2px 5px 2px 5px;
    display: flex;
    align-items: center;
    &:active {
        outline: none;
    }
}
.sphere-chip--border-radius {
    border-radius: 5px;
}

.sphere-chip__content {
    pointer-events: none;
    font-size: 0.75rem;
    font-weight: 400;
    font-family: var(--font-family-roboto);
    color: #d8d8d8;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.sphere-chip__remove {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 5px;
}
