gridster {
    background-color: var(--app-background);
}

gridster > .gridster-row {
    border-top: 1px dotted var(--element-border) !important;
    border-bottom: 1px dotted var(--element-border) !important;
}

gridster > .gridster-column {
    border-left: 1px dotted var(--element-border) !important;
    border-right: 1px dotted var(--element-border) !important;
}

gridster-item:hover .gridster-item-resizable-handler.handle-se {
    border-color: transparent transparent var(--primary-color) !important;
}

gridster-item {
    background-color: transparent !important;
}
