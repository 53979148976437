@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@use './m3-theme';
@use './m3-density-fixes';

:root {
    color-scheme: dark;
}

mat.$theme-ignore-duplication-warnings: true;
.monaco-editor {
    position: absolute !important;
}

html {
    /** GA Release Updates **/
    --background-color-darker: #1b1b1b;
    --background-color-dark: #202020;
    --background-color-light: #565656;

    --color-text-lighter: #ededed;
    --color-text-light: #b7b7b7;

    --font-family-roboto: Roboto, Helvetica, Arial, sans-serif;
    --font-family-system: system-ui, -apple-system, 'Segoe UI', Roboto, Cantarell, sans-serif;
    --font-family-arial: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, sans-serif;
    --font-family-monospace: Consolas, 'Courier New', Courier, monospace;

    --background-color-table-row-header: #111111;
    --background-color-table-row-odd: #212121;
    --background-color-table-row-even: #191919;
    --color-table-row-text-header: var(--color-text-light);
    --color-table-row-text-odd: var(--color-text-light);
    --color-table-row-text-even: var(--color-text-lighter);

    /** Main CSS Variables **/
    --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    --primary-color: #56bc2f;
    --primary-color-dark: rgba(136, 217, 34, 0.06);
    // --primary-color-dark: #0f1508;
    //--primary-color-dark: #17b2930f;
    --color-text: #ffffff;
    --color-text-subtle: #bcbcbc;
    --color-text-subtle-dark: #808080;
    --color-primary: #56bc2f;
    --color-primary-rgb: 86, 188, 47;
    --color-primary-hover: #a0ef6e;

    // Enverus color palette
    --color-deep-lake: #03252f;
    --color-lake: #2e5665;
    --color-lagoon: #006a70;
    --color-ocean: #629fa8;
    --color-mist: #d0ecee;
    --color-sand: #efeee1;
    --color-dark-green: #13322b;
    --color-accent: #005af6;
    --color-accent-rgb: 0, 90, 246;
    --color-warn: #f44336;
    --color-warn-rgb: 244, 67, 54;

    // Enverus accents
    --color-vivid-orange: #ff5f23;
    --color-electric-blue: #005af6;
    --color-electric-blue-light: #2f79f9;
    --color-electric-blue-dark: #0147bb;

    // Monochromatic color palette
    --color-white: #ffffff;
    --color-xxxlight-gray: #f3f3f3;
    --color-xxlight-gray: #cdcdcd;
    --color-xlight-gray: #bcbcbc;
    --color-light-gray: #878787;
    --color-medium-gray: #7a7a7a;
    --color-base-gray: #5b5b5b;
    --color-dark-gray: #454545;
    --color-darker-gray: #303030;
    --color-xdark-gray: #212121;
    --color-xxdark-gray: #171717;
    --color-xxxdark-gray: #1b1b1b;
    --color-black: #000000;

    // font weights
    --font-weight-xlight: 200;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-semibold: 500;
    --font-weight-bold: 600;
    --font-weight-ultra: 800;

    //font sizes
    --font-size-micro: 0.65rem;
    --font-size-tiny: 0.7rem;
    --font-size-xxs: 0.8rem;
    --font-size-xs: 0.85rem;
    --font-size-sm: 0.9rem;
    --font-size-base: 1rem;
    --font-size-md: 1.1rem;
    --font-size-lg: 1.2rem;
    --font-size-xl: 1.3rem;
    --font-size-xxxl: 1.4rem;
    --font-size-big: 2rem;
    --font-size-jumbo: 2.5rem;

    //units
    --unit-micro: 2px;
    --unit-tiny: 5px;
    --unit-xxs: 8px;
    --unit-xs: 10px;
    --unit-sm: 12px;
    --unit-base: 15px;
    --unit-md: 20px;
    --unit-lg: 30px;
    --unit-xl: 40px;
    --unit-xxl: 50px;
    --unit-jumbo: 75px;

    /** Ag-Grid **/
    --ag-border-color: #4c4c4c;
    --ag-checkbox-checked-color: var(--color-primary);
    --ag-range-selection-border-color: var(--color-primary);
    --ag-row-hover-color: rgba(255, 255, 255, 0.1);
    --ag-range-selection-background-color: rgba(var(--color-primary-rgb), 0.1);

    /*** Ag Alpine ***/
    --ag-alpine-active-color: var(--color-primary);
}

/** THEME CORE CONFIGURATION **/
@include mat.elevation-classes();
@include mat.app-background();
body {
    --app-background-color: #303030;
    --text-color: var(--mat-app-text-color);

    &.sphere_theme__light {
        --app-background-color: #efefef;
        --text-color: var(--mat-app-text-color);
    }

    background-color: var(--app-background-color);
    color: var(--text-color);
    @include mat.elevation-classes();
    @include mat.app-background();

    @include mat.all-component-themes(m3-theme.$sphere-theme);
    @include mat.all-component-typographies(m3-theme.$sphere-theme);
    @include mtx.all-component-themes(m3-theme.$sphere-theme);

    .sphere-density__comfortable,
    &.sphere-density__comfortable {
        @include mat.all-component-densities(m3-theme.$sphere-theme);
        @include mtx.all-component-densities(m3-theme.$sphere-theme);
    }

    .sphere-density__compact,
    &.sphere-density__compact {
        @include mat.all-component-densities(m3-theme.$sphere-theme-dense);
        @include mtx.all-component-densities(m3-theme.$sphere-theme-dense);

        @include m3-density-fixes.mat-list-compact-density-fixes();
    }

    .sphere-theme__dark,
    .sphere-theme__light {
        --mat-dialog-container-max-width: 9999px;
    }

    .ag-root {
        @include mat.all-component-densities(m3-theme.$sphere-theme-dense);
        @include mtx.all-component-densities(m3-theme.$sphere-theme-dense);
    }

    .mat-error {
        @include mat.button-theme(m3-theme.$sphere-theme, $color-variant: error);
        @include mat.icon-theme(m3-theme.$sphere-theme, $color-variant: error);
    }
    .mat-secondary {
        @include mat.button-theme(m3-theme.$sphere-theme, $color-variant: secondary);
        @include mat.icon-theme(m3-theme.$sphere-theme, $color-variant: secondary);
    }
    .mat-tertiary {
        @include mat.button-theme(m3-theme.$sphere-theme, $color-variant: tertiary);
        @include mat.icon-theme(m3-theme.$sphere-theme, $color-variant: tertiary);
    }
    .sphere-density__compact,
    &.sphere-density__compact {
        .mat-error {
            @include mat.button-theme(m3-theme.$sphere-theme-dense, $color-variant: error);
            @include mat.icon-theme(m3-theme.$sphere-theme-dense, $color-variant: error);
        }
        .mat-secondary {
            @include mat.button-theme(m3-theme.$sphere-theme-dense, $color-variant: secondary);
            @include mat.icon-theme(m3-theme.$sphere-theme-dense, $color-variant: secondary);
        }
        .mat-tertiary {
            @include mat.button-theme(m3-theme.$sphere-theme-dense, $color-variant: tertiary);
            @include mat.icon-theme(m3-theme.$sphere-theme-dense, $color-variant: tertiary);
        }
    }
}

/** Global Color Variables **/
body {
    --cb-primary-color: #88d922;
    --cb-primary-color-alt: #669d22;

    --background-light: #212121;
    --background-lighter: #353535;
    --card-background: #424242;
    --element-border: rgba(255, 255, 255, 0.12);

    --table-header: #262626;
    --table-row-hover: #3b3b3b;
    --table-row-active: #424242;

    --mat-card-bg: #1c1f20;
    --mat-card-light-bg: #4a4a4a;

    --sidebar-left-color: #fff;
    --sidebar-left-background-color: #0a0a0a;
    --navigation-title-color: #e5e4e4;

    .sphere-theme__light,
    &.sphere-theme__light {
        --background-light: #e8e8e8;
        --background-lighter: #d5d5d5;
        --card-background: #bdbdbd;
        --element-border: rgba(255, 255, 255, 0.12);

        --table-header: #e3e3e3;
        --table-row-hover: #cacaca;
        --table-row-active: #c3c3c3;

        --mat-card-bg: #cccccc;
        --mat-card-light-bg: #bdbdbd;

        --sidebar-left-color: #000000;
        --sidebar-left-background-color: #fbfbfb;
        --navigation-title-color: #222222;
    }

    --status-green: #39b84a;
    --status-green-rgb: 57, 184, 74;
    --status-green-light: #9fff9f;
    --status-green-dark: #7ad97a;

    --status-red: rgb(251, 75, 75);
    --status-red-rgb: 251, 75, 75;
    --status-red-light: #ff3a28;
    --status-red-dark: #d60000;

    --status-yellow: #ebc14f;
    --status-yellow-rgb: 235, 193, 79;
    --status-yellow-light: #ffe404;
    --status-yellow-dark: #e5b700;

    --status-orange: #ff7d00;
    --status-orange-rgb: 255, 125, 0;
    --status-orange-light: #ff9600;
    --status-orange-dark: #ed7700;

    --status-gray: #b3b3b3;
    --status-gray-rgb: 179, 179, 179;
    --status-gray-light: #fbfbfb;
    --status-gray-dark: #ebebeb;

    --status-darkgray: #5d5d5d;
    --status-darkgray-rgb: 93, 93, 93;
    --status-darkgray-light: #6e6e6e;
    --status-darkgray-dark: #4e4e4e;

    --status-black: #5d5d5d;
    --status-black-rgb: 0, 0, 0;

    --status-blue: #00a8ff;
    --status-blue-rgb: 0, 168, 255;
    --status-blue-light: #03b0ff;
    --status-blue-dark: #0096ed;

    --status-darkblue: #235fe7;
    --status-darkblue-rgb: 35, 95, 231;
    --status-darkblue-light: #497bef;
    --status-darkblue-dark: #1046bd;

    --status-purple: #d986ff;
    --status-purple-rgb: 217, 134, 255;
    --status-purple-light: #bd2cff;
    --status-purple-dark: #ac2aeb;

    /** Alternative color versions (DHWS) **/
    --status-alt-green: #42c3a9;
    --status-alt-red: #e93c59;
    --status-alt-yellow: #e9aa3c;
    --status-alt-blue: #3c85e9;

    --price-tick-green: #22ab94;
    --price-tick-red: #f23645;

    /** Material Variables **/
    --mat-dialog-container-max-width: 9999px;
}

.mat-mdc-dialog-container {
    //--mdc-dialog-container-color: #212121;
    --mdc-dialog-supporting-text-color: #fff;
}
