@use 'sass:color';

/** Mixins **/
@mixin gryphon-series($colorVar) {
    fill: $colorVar !important;
    stroke: $colorVar;

    .highcharts-point {
        fill: $colorVar;
        stroke: $colorVar;
    }

    .highcharts-tooltip-box {
        stroke: $colorVar;
    }

    .highcharts-graph {
        stroke: $colorVar;
    }

    &.highcharts-label.highcharts-tooltip-box {
        .highcharts-label-box {
            stroke: $colorVar;
        }
        text > tspan:first-child {
            color: $colorVar;
        }
    }

    &.highcharts-series-legend-icon {
        background-color: $colorVar;
    }
}

@mixin gryphon-series-wrapper($className, $colorVar, $colorVarLight, $colorVarDark) {
    .gryphon-series-#{$className} {
        @include gryphon-series($colorVar);
    }
    .gryphon-series-#{$className}-light {
        @include gryphon-series($colorVarLight);
    }
    .gryphon-series-#{$className}-dark {
        @include gryphon-series($colorVarDark);
    }
}

@mixin gryphon-series-simple-wrapper($className, $colorVar) {
    .gryphon-series-#{$className} {
        @include gryphon-series(($colorVar));
    }

    .gryphon-#{$className} {
        color: $colorVar;
        .highcharts-point {
            color: $colorVar;
        }
    }

    .gryphon-#{$className}-bg {
        background-color: $colorVar;
        .highcharts-point {
            background-color: $colorVar;
        }
    }
}

@mixin highcharts-series-color-scale($scaleName, $baseColor) {
    $color-0: $baseColor;
    $color-1: color.scale($color-0, $lightness: 10%);
    $color-2: color.scale($color-0, $lightness: 20%);
    $color-3: color.scale($color-0, $lightness: 30%);
    $color-4: color.scale($color-0, $lightness: 40%);
    $color-5: color.scale($color-0, $lightness: 50%);
    $color-6: color.scale($color-0, $lightness: 60%);
    $color-7: color.scale($color-0, $lightness: 70%);
    $color-8: color.scale($color-0, $lightness: 80%);
    $color-9: color.scale($color-0, $lightness: 90%);
    .highcharts-scale-#{'' + $scaleName} {
        .highcharts-color-0 {
            @include gryphon-series($color-0);
        }

        .highcharts-color-1 {
            @include gryphon-series($color-1);
        }

        .highcharts-color-2 {
            @include gryphon-series($color-2);
        }

        .highcharts-color-3 {
            @include gryphon-series($color-3);
        }

        .highcharts-color-4 {
            @include gryphon-series($color-4);
        }

        .highcharts-color-5 {
            @include gryphon-series($color-5);
        }

        .highcharts-color-6 {
            @include gryphon-series($color-6);
        }

        .highcharts-color-7 {
            @include gryphon-series($color-7);
        }

        .highcharts-color-8 {
            @include gryphon-series($color-8);
        }

        .highcharts-color-9 {
            @include gryphon-series($color-9);
        }
    }
}
