/** Material Button Toggle Group Styles **/
.button-toggle-group-small {
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-appearance-standard {
        /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-toggle-label-content {
            line-height: 25px;
            font-size: 0.7rem;
            letter-spacing: 0.03rem;
        }

        .button-toggle-group-small--total-indicator {
            margin-left: 5px;
            opacity: 0.3;

            &.has-of-type {
                opacity: 0.5;
            }
        }
    }

    .cell-status-square {
        top: -1px;
    }
}

.button-toggle-group-centered {
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-appearance-standard {
        /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-toggle-label-content {
            display: flex;
            align-items: center;
        }
    }
}
