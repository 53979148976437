.c-host {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;

    .ps-3 {
        padding-left: 1rem !important;
    }

    .pt-1 {
        padding-top: 0.25rem !important;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        margin-left: calc(-0.5 * var(--bs-gutter-x));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-top: calc(-1 * var(--bs-gutter-y));
    }

    .row > * {
        flex-shrink: 0;
        margin-top: var(--bs-gutter-y);
        max-width: 100%;
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        width: 100%;
    }

    .col-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    @media (min-width: 768px) {
        .col-md-1 {
            flex: 0 0 auto;
            width: 8.33333333%;
        }
    }

    @media (min-width: 768px) {
        .col-md-2 {
            flex: 0 0 auto;
            width: 16.66666667%;
        }
    }

    .c-tabs {
        display: flex;
        grid-column-gap: 5px;
        list-style: none;
        padding: 5px 0;
    }

    .c-tab-item {
        border-radius: 4px;
        cursor: pointer;

        .c-tab {
            background-color: transparent;
            border: none;
            cursor: pointer;
            padding: 5px;

            &:hover {
                background-color: rgba(255, 255, 255, 0.11);
            }

            &.active {
                color: var(--primary-color);
            }
        }
    }

    .c-segment {
        padding: 5px 0;

        &.d-flex {
            align-items: center;
            display: flex;
            grid-column-gap: 5px;
        }
    }

    label {
        display: inline-block;
    }

    .form-check {
        display: flex;
        grid-column-gap: 5px;
        min-height: 1.5rem;
        padding-left: 1.5em;
        margin-bottom: 0.125rem;
    }
    .form-check .form-check-input {
        float: left;
        margin-left: -1.5em;
    }
}
