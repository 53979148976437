@use 'variables';
.sphere-primary {
    background-color: variables.$color-primary;
    color: variables.$light-primary-text;
}

/** Status depiction color and background color context classes **/
.status-darkgray--color {
    color: var(--status-darkgray);
}
.status-darkgray--bg-color {
    background-color: var(--status-darkgray);
}

.status-green--color {
    color: var(--status-green);
}
.status-green--bg-color {
    background-color: var(--status-green);
}

.status-yellow--color {
    color: var(--status-yellow);
}
.curve-status--waiting--bg-color {
    background-color: var(--status-yellow);
}
.status-yellow--bg-color {
    background-color: var(--status-yellow);
}

.status-purple--color {
    color: var(--status-purple);
}
.status-purple--bg-color {
    background-color: var(--status-purple);
}

.status-orange--color {
    color: var(--status-orange);
}
.status-orange--bg-color {
    background-color: var(--status-orange);
}

.status-red--color {
    color: var(--status-red);
}
.status-red--bg-color {
    background-color: var(--status-red);
}

.status-gray--color {
    color: var(--status-gray);
}
.status-gray--bg-color {
    background-color: var(--status-gray);
}
.status-gray--color {
    color: var(--status-gray);
}
.status-gray--bg-color {
    background-color: var(--status-gray);
}

.status-blue--color {
    color: var(--status-blue);
}
.status-blue--bg-color {
    background-color: var(--status-blue);
}

/** Cell status circle class **/
.cell-status-circle {
    width: 5px;
    height: 5px;
    border-radius: 10px;
    margin-left: 1px;
    margin-right: 1px;

    &.cell-status--blue {
        background-color: var(--status-blue);
    }

    &.cell-status--dark-red {
        background-color: #c0504d; // TODO: var --status-dark-red
    }

    &.cell-status--dark-grey {
        background-color: #7d7d7d3b; // TODO: var --status-dark-grey
    }

    &.cell-status--light-grey {
        background-color: #7d7d7d; // TODO: var --status-light-grey
    }

    &.cell-status--primary {
        background-color: var(--primary-color);
    }

    &.cell-status--dark-orange {
        background-color: var(--status-orange);
    }
}

/** Cell status square classes **/
.cell-status-square {
    position: relative;
    top: 0;

    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 5px;
}

/* Curve status styles */
.cell-status--green {
    .cell-status-square {
        background-color: var(--status-green);
    }
}

.cell-status--yellow {
    .cell-status-square {
        background-color: var(--status-yellow);
    }
}

.cell-status--blue {
    .cell-status-square {
        background-color: var(--status-blue);
    }
}

.cell-status--purple {
    .cell-status-square {
        background-color: var(--status-purple);
    }
}

.cell-status--orange {
    .cell-status-square {
        background-color: var(--status-orange);
    }
}

.cell-status--red {
    .cell-status-square {
        background-color: var(--status-red);
    }
}

.cell-status--gray {
    .cell-status-square {
        background-color: var(--status-gray);
    }
}

.cell-status--darkgray {
    .cell-status-square {
        background-color: var(--status-darkgray);
    }
}

.notification__icon--success {
    color: var(--status-green);
}
.notification__icon--info {
    color: var(--status-blue);
}
.notification__icon--warning {
    color: var(--status-yellow);
}
.notification__icon--error {
    color: var(--status-red);
}
.notification__icon--in-progress {
    color: var(--color-accent);
}
