@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
@use './m3-theme';

@mixin mat-list-compact-density-fixes() {
    .mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before,
    .mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before,
    .mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before,
    .mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before {
        height: 22px;
    }

    .mdc-list-item--with-two-lines .mdc-list-item__primary-text::before,
    .mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
        height: 20px;
    }
}
