/** Material Table Styles **/
// TODO: Use variables for colors.

.mat-mdc-table {
    background-color: #303030;
}

.mat-mdc-header-cell {
    background-color: #262626;
}

.sphere-mat-table {
    .mat-mdc-row:hover {
        background: var(--table-row-hover);
    }

    .mat-mdc-row:not(.expanded-row):active {
        background: var(--table-row-active);
    }

    &.sphere-mat-table--expandable {
        .mat-mdc-row:not(.detail-row) {
            cursor: pointer;
            border-bottom-width: 0;
        }
    }

    .mat-mdc-row.detail-row {
        height: 0;
    }

    .mat-mdc-row.detail-row td {
        border-top-width: 0;
        border-bottom-width: 0;
        background-color: var(--table-header);
    }

    .mat-mdc-row.expanded-row td {
        border-bottom: 1px solid var(--cb-primary-color);
    }

    .mat-mdc-row.selected-row {
        background-color: #3c3c3c;

        &:hover {
            background-color: #464646;
        }
    }
}

.mat-mdc-table--dense {
    .mat-mdc-header-row {
        height: 36px;
    }

    .mat-mdc-row {
        height: 40px;
    }
}

.mat-mdc-table--row-colors {
    .mat-mdc-row__odd {
        background-color: var(--background-color-table-row-odd);

        .mat-mdc-cell {
            border: none;
        }
    }

    .mat-mdc-row__even {
        background-color: var(--background-color-table-row-even);

        .mat-mdc-cell {
            border: none;
        }
    }
}
