@use 'variables';
@use 'mixins';
@use 'animations';

/** NON-MATERIAL Input Fields **/
input {
    font-family: var(--font-family);
    color: rgba(variables.$light-primary-text, 0.7);
    background-color: #313131;
    border: 1px solid rgb(121, 121, 121);
    padding: 5px 8px;

    transition:
        0.1s ease-in border-color,
        0.1s ease-in color;

    &:hover {
        border-color: rgb(172, 172, 172);
    }

    &:focus {
        color: variables.$light-primary-text;
        border-color: variables.$color-white;
        outline: none;
    }

    &.input-small {
        height: 20px;
        font-size: 13px;
        padding: 0 5px 2px 5px;
    }

    &:disabled,
    &:disabled &:hover {
        border-color: inherit;
    }
}
