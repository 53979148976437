// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

// Note: Color palettes are generated from primary: #88d922, secondary: #005af6
$_palettes: (
    primary: (
        0: #000000,
        10: #0f2000,
        20: #1d3700,
        25: #254300,
        30: #2d5000,
        35: #355c00,
        40: #3d6a00,
        50: #4e8500,
        60: #60a100,
        70: #73bf00,
        80: #8bdc26,
        90: #a5f944,
        95: #d1ff9e,
        98: #f0ffd8,
        99: #f8ffe9,
        100: #ffffff
    ),
    secondary: (
        0: #000000,
        10: #0b2005,
        20: #203617,
        25: #2b4121,
        30: #364d2b,
        35: #425936,
        40: #4d6541,
        50: #657e58,
        60: #7f9870,
        70: #99b389,
        80: #b3cfa3,
        90: #cfebbd,
        95: #ddf9cb,
        98: #edffdf,
        99: #f7ffed,
        100: #ffffff
    ),
    tertiary: (
        0: #000000,
        10: #00201f,
        20: #003735,
        25: #104240,
        30: #1f4e4c,
        35: #2c5a57,
        40: #386663,
        50: #517f7c,
        60: #6b9996,
        70: #85b4b1,
        80: #a0cfcc,
        90: #bbece8,
        95: #c9faf6,
        98: #e3fffc,
        99: #f2fffd,
        100: #ffffff
    ),
    neutral: (
        0: #000000,
        10: #1b1c18,
        20: #30312c,
        25: #3b3c37,
        30: #464742,
        35: #52534d,
        40: #5e5f59,
        50: #777772,
        60: #91918b,
        70: #abaca5,
        80: #c7c7c0,
        90: #e3e3db,
        95: #f2f1ea,
        98: #fafaf2,
        99: #fdfcf5,
        100: #ffffff,
        4: #0d0f0b,
        6: #121410,
        12: #1f201c,
        17: #292a26,
        22: #343530,
        24: #383a35,
        87: #dbdad3,
        92: #e9e8e1,
        94: #efeee7,
        96: #f5f4ec
    ),
    neutral-variant: (
        0: #000000,
        10: #191d14,
        20: #2e3228,
        25: #393d32,
        30: #44483d,
        35: #505449,
        40: #5c6054,
        50: #75796c,
        60: #8e9285,
        70: #a9ad9f,
        80: #c4c8ba,
        90: #e1e4d5,
        95: #eff2e3,
        98: #f8fbec,
        99: #fbfeee,
        100: #ffffff
    ),
    error: (
        0: #000000,
        10: #410002,
        20: #690005,
        25: #7e0007,
        30: #93000a,
        35: #a80710,
        40: #ba1a1a,
        50: #de3730,
        60: #ff5449,
        70: #ff897d,
        80: #ffb4ab,
        90: #ffdad6,
        95: #ffedea,
        98: #fff8f7,
        99: #fffbff,
        100: #ffffff
    )
);

$_rest: (
    secondary: map.get($_palettes, secondary),
    neutral: map.get($_palettes, neutral),
    neutral-variant: map.get($_palettes, neutral-variant),
    error: map.get($_palettes, error)
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

/**
  Dark theme definition
 */
$theme-config: (
    color: (
        primary: $_primary,
        tertiary: $_tertiary,
        theme-type: dark
    ),
    typography: (
        brand-family: 'Roboto'
    ),
    density: (
        scale: 0
    )
);

$theme-dense-config: (
    color: (
        primary: $_primary,
        tertiary: $_tertiary,
        theme-type: dark
    ),
    typography: (
        brand-family: 'Roboto'
    ),
    density: (
        scale: -3
    )
);

$sphere-theme: mat.private-deep-merge-all(mat.define-theme($theme-config), mtx.define-theme($theme-config));
$sphere-theme-dense: mat.private-deep-merge-all(mat.define-theme($theme-dense-config), mtx.define-theme($theme-dense-config));
