h2 {
    font-weight: 400;
}

h3,
h4 {
    font-weight: 500;
}

.anchor-link {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.color-primary {
    color: var(--color-primary);
}

.color-warn {
    color: var(--color-warn);
}

.color-warn--semibold {
    color: var(--color-warn);
    font-weight: var(--font-weight-semibold);
}

.color-accent {
    color: var(--color-accent);
}

.color-primary--force {
    color: var(--color-primary) !important;
}

.color-warn--force {
    color: var(--color-warn) !important;
}
.color-accent--force {
    color: var(--color-accent) !important;
}

.primary-match {
    color: var(--color-primary);
    font-weight: 400;
}

.color-darker {
    color: var(--color-text-subtle);
}

.color-sand {
    color: var(--color-sand);
}

.sphere-form-field__label {
    font-family: var(--font-family-roboto);
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    color: #d8d8d8;
}
