@use 'variables';
@use 'mixins';
@use 'animations';

.btn {
    padding: variables.$unit-xxs variables.$unit-sm;
    background-color: transparent;
    color: variables.$color-primary;
    font-family: variables.$font-1;
    font-size: variables.$font-size-sm;
    font-weight: 600;
    outline: 0;
    text-transform: uppercase;
    border: 0;
    border-radius: 0;
    transition: all 120ms ease-in-out;

    &:hover {
        cursor: pointer;
    }

    &.btn-primary {
        background-color: variables.$color-primary;
        color: variables.$color-black;

        &:hover {
            background-color: variables.$color-primary-hover;
        }
    }

    &.btn-gray {
        background-color: variables.$color-light-gray;
        color: variables.$color-black;

        &:hover {
            background-color: variables.$color-xlight-gray;
        }
    }
}

.small-icon-button {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;

    .mat-icon {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px !important;
    }
    .material-icons {
        font-size: 16px !important;
    }
}
