.mat-mdc-list-item {
    &.active-nav {
        color: var(--primary-color);
        background-color: rgba(255, 255, 255, 0.06);

        & mat-icon,
        h4 {
            color: var(--primary-color) !important;
        }
    }

    &.mat-mdc-list-item-square {
        border-radius: 0 !important;
    }
}

.mat-mdc-list-item {
    &.version-picker__version-tile {
        padding: 5px;
        height: 48px !important;
    }
}
