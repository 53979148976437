body {
    --snackbar-background: #3c3c3c;
}

.mat-snackbar-info,
.mat-snackbar-success,
.mat-snackbar-warning,
.mat-snackbar-error {
    color: var(--color-text);
    background-color: var(--snackbar-background);
    border-left-width: 3px;
    border-left-style: solid;
}

.mat-snackbar-info {
    border-left-color: var(--status-blue);
}

.mat-snackbar-success {
    border-left-color: var(--primary-color);
}

.mat-snackbar-error {
    border-left-color: var(--status-red);
}

.mat-snackbar-warning {
    border-left-color: var(--status-yellow);
}

.mdc-snackbar__surface {
    background-color: var(--snackbar-background) !important;
}

.mat-mdc-snack-bar-label {
    color: #ffffff !important;
}

.mat-mdc-snack-bar-action .mdc-button__label {
    color: #ffffff;
}
