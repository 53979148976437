@mixin rotate-element($degrees: 0) {
    .rotate-cw-#{'' + $degrees} {
        transform: rotate(#{$degrees}deg);
    }
    .rotate-ccw-#{'' + $degrees} {
        transform: rotate(-#{$degrees}deg);
    }
}

@include rotate-element(45);
@include rotate-element(90);
@include rotate-element(135);
@include rotate-element(180);
@include rotate-element(225);
@include rotate-element(270);
